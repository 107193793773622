<template>
  <div
    class="flex flex-col items-center justify-center"
    :style="{
      height: typeof props.height === 'number' || !Number.isNaN(Number(props.height)) ? props.height + 'px' : props.height
    }"
  >
    <img
      src="~/assets/images/common/loading.apng"
      :alt="$t('加载中...')"
      :style="{
        width: props.imgSize + 'px'
      }"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  imgSize: {
    type: [Number, String],
    default: 100
  },
  height: {
    type: [Number, String],
    default: 300
  }
})
</script>

<style lang="scss" scoped></style>
